<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :class="`elevation-${hover ? 5 : 1}`"
      class="Card_Apuracao clickable" fill-height @click="selectItem(item)">
      <v-card-title class="Card_Title pa-2 justify-center">
        {{ $te(item.titulo, 1) ? $tc(item.titulo, 1) : item.titulo }}
      </v-card-title>
      <v-card-text class="Card_Content py-0">
        <v-row justify="center" align="center">
          <p v-bind:style="{color: corValorPercentual(item)}" class="headline font-weight-light">
            {{ getPercent(item.valorPercentual) }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions class="Card_Actions">
        <v-row class="text-xs-center" align="end">
          <v-col cols="6" class="py-3">
            {{ indModoVarejo ? $t('label.total_recebimento') : $tc('label.total_pagamento', 2) }}: <br />
            {{ getMoney(item.totalRecebimentos) }}
          </v-col>
          <v-col cols="6" class="py-0">
            <v-card v-bind:style="{background: corCardMenor(item)}" class="white--text text-center">
              {{ statusComposicao(item.status) }}
            </v-card>
          </v-col>
          <v-col cols="12" style="text-align: end;"  class="py-1">
            <v-icon v-if="false" @click.native="abrirModalConfirmacao('exportar')">get_app</v-icon>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>
<confirm
  ref="modalConfirmacao"
  :message="mensagemConfirmacao"
  @agree="validarAcao">
</confirm>

<script>
import { mapGetters } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoCardStatus',
  props: {
    item: Object,
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canEdit',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    habilitarFinalizarApuracao() {
      return this.item.status === 'AGUARDANDO_APURACAO'
        && this.canEdit;
    },
  },
  methods: {
    getMoney,
    getPercent,
    selectItem(item) {
      this.$router.push({
        name: 'listarApuracoesContratoNivel2Status',
        params: {
          status: item.status,
        },
      });
    },
    abrirFinalizacaoLote(e, item) {
      e.stopPropagation();
      const routerFinalizarLote = this.indModoVarejo
        ? 'finalizarLoteApuracoesContratoStatus'
        : 'finalizarLoteApuracoesContratoIndustriaStatus';
      this.$router.push({
        name: routerFinalizarLote,
        params: {
          status: item.status,
        },
      });
    },
    corValorPercentual(item) {
      if (item.status === 'APURACAO_PREVIA') {
        return 'rgb(149, 165, 166)';
      } if (item.status === 'AGUARDANDO_APURACAO') {
        return 'rgb(30, 136, 229)';
      } if (item.status === 'AGUARDANDO_APROVACAO') {
        return 'rgb(230, 126, 34)';
      } if (item.status === 'EM_ANALISE') {
        return 'rgb(241, 196, 15)';
      } if (item.status === 'APROVADO') {
        return 'rgb(26, 188, 156)';
      } if (item.status === 'REPROVADO') {
        return 'rgb(231, 76, 60)';
      } if (item.status === 'CANCELADO') {
        return 'rgb(126, 87, 194)';
      }
      return '#757575';
    },
    statusComposicao(status) {
      if ((status === 'APURACAO_PREVIA') || (status === 'AGUARDANDO_APURACAO') || (status === 'AGUARDANDO_APROVACAO')) {
        return 'Pend. Apuração';
      } if (status === 'EM_ANALISE') {
        return 'Em Análise';
      } if (status === 'APROVADO') {
        return 'Aprovado';
      } if ((status === 'REPROVADO') || (status === 'CANCELADO') || (status === 'VENCIDO')) {
        return 'Apuração Rejeitada';
      }
      return '';
    },
    corValorPercentualAprovado(item) {
      if (this.isVisaoCliente || this.isVisaoHolding
        || this.isVisaoFornecedor || this.isVisaoGrupoFornecedor
        || this.isVisaoRegional) {
        if (item.valor_percentual_aprovado === 0) {
          return 'rgb(149, 165, 166)';
        } if (item.valor_percentual_aprovado > 0 && item.valor_percentual_aprovado < 100) {
          return 'rgb(230, 126, 34)';
        } if (item.valor_percentual_aprovado === 100) {
          return 'rgb(26, 188, 156)';
        }
      }

      return '#757575';
    },
    corValorPercentualAgAprovacao() {
      if (this.isVisaoCliente || this.isVisaoHolding
        || this.isVisaoFornecedor || this.isVisaoGrupoFornecedor
        || this.isVisaoRegional) {
        return 'rgb(0, 0, 0)';
      }

      return '#757575';
    },
    corCardMenor(item) {
      if (item.status === 'APURACAO_PREVIA'
        || item.status === 'AGUARDANDO_APURACAO'
        || item.status === 'AGUARDANDO_APROVACAO'
        || item.status === 'EM_ANALISE') {
        return 'rgb(230, 126, 34)';
      }

      if (item.status === 'APROVADO') {
        return 'rgb(26, 188, 156)';
      }

      if (item.status === 'REPROVADO'
        || item.status === 'CANCELADO'
        || item.status === 'VENCIDO') {
        return 'rgb(231, 76, 60)';
      }

      return '#757575';
    },
    validarAcao() {
    },
    abrirModalConfirmacao(acao) {
      if (acao === 'editar') {
        this.mensagemConfirmacao = this.$t('message.deseja_editar_item');
      } else if (acao === 'enviar_analise') {
        this.mensagemConfirmacao = this.$t('message.deseja_enviar_analise');
      } else if (acao === 'aprovar') {
        this.mensagemConfirmacao = this.$t('message.deseja_aprovar_item');
      } else if (acao === 'exportar') {
        this.mensagemConfirmacao = this.$t('message.deseja_exportar');
      } else {
        this.mensagemConfirmacao = this.$t('message.deseja_reprovar_item');
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
  },
};
</script>

<style>

.Card_Apuracao {
  min-height: 146px !important;
  height: 100%;
}
.Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
  color: #757575;
}
</style>
