<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :class="`elevation-${hover ? 5 : 1}`"
            class="Card_Apuracao clickable" fill-height @click="selectItem(item)">
      <v-card-title class="Card_Title pa-2 justify-center">
        <popover-lista :campos="camposPopover" :offset-x="false" :offset-y="true"
                       :objeto="item" class="ApuracaoCardContrato__popover-lista" :nudge-width="300">
          <template slot="ativadorPopover">
            <span class="title text-center">{{ item.origemContrato || item.focoContrato }}</span>
          </template>
        </popover-lista>
      </v-card-title>
      <v-card-text class="Card_Content py-0">
        <v-row justify="center" align="center">
          <p class="headline font-weight-light">
            {{ getMoney(item.totalRecebimentos) }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions class="Card_Actions">
        <v-row class="text-xs-center" align="end">
          <v-col cols="6" class="py-3">
            {{ indModoVarejo ? $t('label.total_recebimento') : $tc('label.total_apuracoes', 2) }}: <br />
            {{ (item.totalApuracoes) }}
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import PopoverLista from '../../shared-components/PopoverLista';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoCardContrato',
  components: {
    PopoverLista,
  },
  props: {
    item: Object,
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canEdit',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    camposPopover() {
      const campos = [];

      campos.push({
        campo: 'descricao',
        label: this.$t('label.descricao_contrato'),
      });

      campos.push({
        campo: 'unidadeNegocio',
        semLabel: true,
      });

      return campos;
    },
    habilitarFinalizarApuracao() {
      return this.item.temAcaoAguardandoApuracao
        && this.canEdit;
    },
  },
  methods: {
    quantosEspacos(item) {
      let espacos = 0.0;

      if (this.habilitarFinalizarApuracao) {
        espacos += 1.5;
      }

      if (item.extensaoRecursiva) {
        espacos += 1.5;
      }

      return Math.floor(6 - espacos);
    },
    getMoney,
    getPercent,
    selectItem(item) {
      this.$router.push({
        name: 'listarApuracoesContratoNivel2Contrato',
        params: {
          idContrato: item.codContrato,
          isContrato: true,
          origemContrato: item.origemContrato || item.focoContrato,
        },
        query: {
          origemContrato: item.origemContrato || item.focoContrato,
        },
      });
    },
    abrirFinalizacaoLote(e, item) {
      e.stopPropagation();
      const routerFinalizarLote = this.indModoVarejo
        ? 'finalizarLoteApuracoesContrato'
        : 'finalizarLoteApuracoesContratoIndustria';
      this.$router.push({
        name: routerFinalizarLote,
        params: {
          idContrato: item.codContrato,
        },
        query: {
          origemContrato: item.origemContrato || item.focoContrato,
        },
      });
    },
    dataFimEInicioIgual(dataFim, dataInicio) {
      return this.converterMesAno(dataFim) === this.converterMesAno(dataInicio);
    },
    converterMesAno(val) {
      return this.moment(val).format('MMM/YY');
    },
  },
};
</script>

<style>
  .ApuracaoCardContrato__popover-lista {
    width: 100%;
  }
  .clickable {
    cursor: pointer;
  }
  .Card_Apuracao {
    min-height: 146px !important;
    height: 100%;
  }
  .Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
    color: #757575;
  }
</style>
